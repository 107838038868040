//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isEmpty } from "@/utils/validate";
import moment from "moment";
import {MessageBox} from "element-ui";
import codeImg from '@/static/picture/code.png';

let checkPhone = (rule, value, callback) => {
  var reg = /^1\d{10}$/; //手机号
  if (!reg.test(value)) {
    callback(new Error("请输入正确的手机号码"));
  } else {
    callback();
  }
};
export default {
  layout: "signUp",
  data() {
    return {
      showModel: false,
      yzsfForm: {
        code: ""
      },
      yzsfRules: {
        code: [{ required: true, message: "请输入图形验证码", trigger: "blur" }]
      },
      picCodeUrl: "",
      loginWay: 1,
      yzmForm: {
        account: "",
        graphCode: "",
        secret: ""
      },
      yzmRules: {
        account: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: checkPhone, trigger: "blur" }
        ],
        graphCode: [
          { required: true, message: "请输入图形验证码", trigger: "blur" }
        ],
        secret: [
          { required: true, message: "请输入短验信证码", trigger: "blur" }
        ]
      },
      loginForm: {
        account: "",
        secret: ""
      },
      loginRules: {
        account: [
          {
            required: true,
            message: "请输入手机号码/邮箱/用户名",
            trigger: "blur"
          },
          /*{
            trigger: "blur",
            validator: (rule, value, callback) => {
              var reg = /^1\d{10}$/; //手机号
              var reg2 = /[\d\w\._-]+@[\d\w]+\.[\w]+/; //邮箱
              var reg3 = /^[A-Za-z0-9@]+$/;
              if (!reg.test(value) && !reg2.test(value) && !reg3.test(value)) {
                callback(new Error("请输入正确的手机号码/邮箱"));
              } else {
                callback();
              }
            }
          }*/
        ],
        secret: [{ required: true, message: "请输入密码", trigger: "blur" }]
      },
      picCodeLoading: false,
      isQuery: !(
        this.$route.path.includes("queryReport") && this.$route.query.query == 1
      )
        ? false
        : true,
      isRepeat: false,
      errorOnly: false,
      erroMsgCode: null,
      countDown: 0,
      btnText: "获取验证码",
    };
  },
  async asyncData({ error, query, $axios, params }) {
    try {
      const [res] = await Promise.all([$axios.get("/api/Auth/initLogin")]);
      return {
        initData: res.data.data,
        queryPam: query
      };
    } catch (err) {
      error(err);
    }
  },
  head() {
    return {
      title: "用户登录-飙局",
      meta: [
        {
          name: "description",
          content: ""
        },
        {
          name: "keywords",
          content: ""
        }
      ]
    };
  },
  mounted() {
    let token = localStorage.getItem("uc_token");
    if (!isEmpty(token) && token != "undefined" && token != "null") {
      this.getUserInfo();
    } else {
      this.getPicCode();
    }
    if (JSON.stringify(this.queryPam) != "{}") {
      this.$axios.post("/api/auth/syncLogin", this.queryPam).then(res => {
        if (res.data.code == 0) {
          this.loginOk(res);
        } else {
          window.location.href = "https://nav.chaofan.wang/";
        }
      });
    }
  },
  methods: {
    getUserInfo() {
      this.$axios
        .post("/api/auth/getUserInfo", { isGoLogin: true })
        .then(res => {
          if (res.data.code === 0) {
            this.$router.push("/");
          }else{
            this.getPicCode();
          }
        });
    },
    accountStatus() {
      this.$refs.loginRules.validateField(["account"], valid => {
        if (!valid) {
          this.$axios
            .post("/api/auth/accountStatus", {
              account: this.loginForm.account
            })
            .then(res => {
              if (res.data.code != 1) {
                this.onlyErrorMsg(res.data.msg);
              }
            });
        }
      });
    },
    onlyErrorMsg(msg) {
      if (!this.errorOnly) {
        this.errorOnly = true;
        setTimeout(() => {
          this.errorOnly = false;
        }, 2000);
        this.$message.error(msg);
      }
    },
    sfyzSubmit() {
      this.$refs.yzsfRules.validate(valid => {
        if (valid) {
          this.reqLogin();
        }
      });
    },
    submitForm() {
      if (!this.isRepeat) {
        this.isRepeat = true;
        setTimeout(() => {
          this.isRepeat = false;
        }, 2000);
        if (this.loginWay == 1) {
          this.$refs.yzmRules.validate(valid => {
            if (valid) {
              this.reqLogin();
            } else {
              return false;
            }
          });
        } else {
          this.$refs.loginRules.validate(valid => {
            if (valid) {
              this.$axios
                .post("/api/auth/accountStatus", {
                  account: this.loginForm.account
                })
                .then(res => {
                  if (res.data.code == 1) {
                    let failCount = res.data.data.fail_times;
                    if (failCount >= 5) {
                      //已经错误5次及以上 弹框验证
                      this.getPicCode();
                      this.showModel = true;

                    } else {
                      this.reqLogin();
                    }
                  } else {
                    this.onlyErrorMsg(res.data.msg);
                  }
                });
            } else {
              return false;
            }
          });
        }
      }
    },
    reqLogin() {
      let form = this.loginForm;
      if (this.loginWay == 1) {
        form = this.yzmForm;
      }
      form.way = this.loginWay;
      if (this.yzsfForm.code) {
        form.graphCode = this.yzsfForm.code;
      }
      const loading = this.$loading();
      this.$axios.post("/api/Auth/login", form).then(res => {
        loading.close();
        this.loginOk(res);
      });
    },
    loginOk(res) {
      if (res.data.code == 0) {
        /* 到期提醒 */
        this.renewNotice(res.data.data);


        //成功
        if (this.queryPam && this.queryPam.jump && this.queryPam.jump.indexOf("popularize") > -1) {
          this.$router.push("/popularize");
        } else if (this.queryPam && this.queryPam.jump) {
          this.$router.push(this.queryPam.jump);
        } else {
          this.$router.push("/");
        }

        /*if (this.queryPam && this.queryPam.jump.indexOf("popularize") > -1) {
          this.$router.push("/popularize");
        } else {
          this.$router.push("/");
        }*/

        this.$axios.post("/api/auth/getUserInfo").then(res => {
          this.$store.commit("setUser", res.data.data);
        });
      } else if (res.data.code == -10) {
        this.erroMsgCode = Math.random() + "";
        this.$nextTick(() => {
          this.erroMsgCode = res.data.msg;
        });
        this.getPicCode();
        return;
      } else {
        this.onlyErrorMsg(res.data.msg);
      }
      this.showModel = false;
      this.yzsfForm.code = null;
    },
    getPicCode() {
      this.picCodeLoading = true;
      this.$axios.get("/api/Auth/graphVerifyCode").then(res => {
        this.picCodeUrl = res.data;
        this.picCodeLoading = false;
      });
    },
    getMobileCode() {
      //scene 场景1=注册，2=验证码登录，3=找回密码手机验证5=更换手机号之验证旧手机，6=更换手机号之验证新手机 默认2
      this.$refs.yzmRules.validateField(["account"], phoneMsg => {
        if (!phoneMsg) {
          this.$refs.yzmRules.validateField(["graphCode"], picCodeMsg => {
            if (!picCodeMsg) {
              if (!this.isRepeat) {
                this.isRepeat = true;
                setTimeout(() => {
                  this.isRepeat = false;
                }, 2000);
                if (this.countDown > 0) {
                  return;
                }

                this.$axios
                  .post("/api/Auth/mobileVerifyCode", {
                    phone: this.yzmForm.account,
                    graphCode: this.yzmForm.graphCode,
                    scene: 2
                  })
                  .then(res => {
                    if (res.data.code == 0) {
                      this.$message({
                        message: "获取成功，请留意手机短信",
                        type: "success"
                      });
                      this.countDown = 60;
                      this.btnText = "重发验证码";
                      this.interval = setInterval(() => {
                        if (this.countDown > 0) {
                          this.countDown--;
                          console.log(this.countDown);
                        } else {
                          clearInterval(this.interval);
                          this.interval = null;
                        }
                      }, 1000);
                    } else {
                      this.getPicCode();
                      this.$message.error(res.data.msg);
                    }
                  });
              }
            }
          });
        }
      });
    },
    // 续费通知
    renewNotice(data){
      let fortyDaysAgo = moment(data.uc_vip_exp).subtract(39, 'days').format('YYYY-MM-DD'); // 到期前40天
      let thirtyDaysAgo = moment(data.uc_vip_exp).subtract(29, 'days').format('YYYY-MM-DD'); // 到期前30天
      let tenDaysAgo = moment(data.uc_vip_exp).subtract(9, 'days').format('YYYY-MM-DD'); // 到期前10天
      let day = moment().startOf("day").format('YYYY-MM-DD'); //今天的时间
      let dueTimeTip = this.$cookies.get("dueTimeTip");
      if(day === fortyDaysAgo || day === thirtyDaysAgo || day === tenDaysAgo){
        if(dueTimeTip) return
        this.$axios.get("/api/Inmail/renewNotice").then(res => {
          this.$cookies.set("dueTimeTip", true);
          if(res.data.data.length === 0) return
          MessageBox.alert(`<p>${res.data.data.content}</p><div class="codeImgTip"><img src="${codeImg}"/></div>`, '提示', {
            dangerouslyUseHTMLString: true,
            confirmButtonText: '了解更多',
            center: true,
            type: 'warning',
            customClass: 'commonTip'
          }).then(()=> {
            this.$router.push({
              path:"/user/VersionIntroduction",
            })
          });
          /*MessageBox.confirm(res.data.data.content, '提示', {
            confirmButtonText: '确定',
            type: 'warning'
          })*/
        })
      } else {
        this.$cookies.set("dueTimeTip", false);
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
    this.interval = null;
  }
};
